<div class="main">
    <div class="left" [ngClass]="isAtLeastMedium ? 'flex-basis-45' : 'flex-basis-100'">
        <div class="body">
            <router-outlet></router-outlet>
        </div>

        <div class="footer">
            <div>© Energies Demain</div>
            <div class="contact"><i class="icon-mail-01"></i>contact&#64;templateweb.fr</div>
        </div>
    </div>

    <div class="right" [ngClass]="isAtLeastMedium ? 'd-flex' : 'd-none'">
        <div class="slogan-container">
            <div class="logo">
                <img src="../../../assets/img/logo_header.png" alt="logo" />
            </div>
            <div class="slogan-text">
                Expérimenter et accompagner les transitions pour un avenir durable
            </div>
        </div>
        <div class="image-container">
            <div class="image"></div>
        </div>
    </div>
</div>
