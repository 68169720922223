<div class="page-heading">
    <div class="layer-title">Modifier mon mot de passe</div>
</div>

<div class="modal-content-body d-flex-center">
    <div class="panel-row d-flex-justify-content-center">
        <div class="description">
            Veuillez renseigner votre ancien mot de passe suivi du nouveau, à garder précieusement.
        </div>
    </div>

<div class="container h-100 d-flex justify-content-center align-items-center">
    <form [formGroup]="passwordFormGroup">
        <div class="panel-row">
            <div class="width-500">
                <label for="currentPassword">Ancien mot de passe</label>
                <ed-textfield
                    innerId="currentPassword"
                    [type]="isCurrentPasswordVisible ? 'text' : 'password'"
                    (keyup)="onKeyUpCheckCurrentPassword()"
                    placeholder="Saisissez votre mot de passe actuel"
                    formControlName="currentPassword"
                    (onIconClick)="toggleCurrentPasswordVisibility()"
                    [icon]="isCurrentPasswordVisible ? 'icon-eye' : 'icon-eye-off'"
                    [iconPosition]="'right'"
                    [required]="true"
                    [iconClickable]="true"></ed-textfield>
            </div>
        </div>
        <div class="panel-row">
            <div class="width-500">
                <label for="newPassword">Nouveau mot de passe</label>
                <ed-textfield
                    innerId="newPassword"
                    [type]="isNewPasswordVisible ? 'text' : 'password'"
                    (keyup)="onKeyUpCheckNewPassword()"
                    placeholder="Choisissez votre nouveau mot de passe"
                    formControlName="newPassword"
                    (onIconClick)="toggleNewPasswordVisibility()"
                    [icon]="isNewPasswordVisible ? 'icon-eye' : 'icon-eye-off'"
                    [iconPosition]="'right'"
                    [required]="true"
                    [iconClickable]="true"></ed-textfield>
            </div>
        </div>
    </form>
</div>
</div>

<div class="modal-content-footer layer-actions">
    <ed-button [colorSchema]="buttonColors.Secondary" (click)="close()">Annuler</ed-button>
    <ed-button
        [disabled]="!passwordFormGroup.dirty || !isNewPasswordValid || passwordFormGroup.invalid"
        (click)="changePassword()"
        [loading]="isSaving">
        Enregistrer le mot de passe
    </ed-button>
</div>
