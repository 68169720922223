import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { RestService } from '../../../core/services/rest.service';

import { environment } from '../../../../environments/environment';
import { Organization } from 'src/app/core/models/organization.model';

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    private _organizations = false;
    private organizationSubject = new BehaviorSubject<any>(null); // Valeur par défaut `null`
    organization$ = this.organizationSubject.asObservable();
    private updateListOrga = new Subject<void>();
    updateListOrga$ = this.updateListOrga.asObservable();

    constructor(
        @Inject(RestService) private restService: RestService,
        private readonly http: HttpClient,
    ) {}

    triggerUpdateListOrga() {
        this.updateListOrga.next();
    }

    public get organizations(): boolean {
        return this._organizations;
    }

    setOrganization(org: any) {
        this.organizationSubject.next(org || []);
    }

    getOrganization() {
        return this.organization$;
    }

    private _getBaseUrl(organization: string): string {
        const baseUrl = `${environment.apiUrl}${organization}`;
        return baseUrl;
    }

    public async getAllOrganizations(): Promise<Organization[]> {
        const path = '/organization/all';
        const organizations = await this.restService.get(path, {});
        return organizations;
    }

    public async getOrganizationById(orgId: string): Promise<Organization> {
        const path = `/organization/infos/${orgId}`;
        const organization = await this.restService.get(path, {});
        return organization;
    }

    public async deleteOrganization(orgId: string): Promise<void> {
        const path = `/organization/${orgId}`;
        await this.restService.delete(path, {});
    }

    public async updateOrganization(organization: Partial<Organization>): Promise<void> {
        const path = `/organization`;
        await this.restService.put(path, {}, organization);
    }

    public async createOrganization(organization: Partial<Organization>): Promise<void> {
        const path = `/organization`;
        await this.restService.post(path, {}, organization);
    }
}
