import { Component, inject, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    first,
    map,
    Observable,
    of,
    Subject,
    Subscription,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import {
    AlertService,
    DropdownMenuItem,
    LayerService,
    LayerSize,
    PopupService,
    PopupTypes,
} from '@ed---interne/ng-uui-components';

import { AuthState } from 'src/app/core/states/auth.state';
import { User } from 'src/app/core/models/user.model';
import {
    UsersState,
    DeleteUser,
    GetPaginatedUsers,
} from 'src/app/features/admin/users/users.state';
import { UsersPaginationState } from 'src/app/features/admin/users/users-pagination.state';

import { EdModalConfirmComponent } from 'src/app/shared/components/modals/ed-modal-confirm/ed-modal-confirm.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserUpdateComponent } from './user-update/user-update.component';
import {
    DisplayedColumns,
    DisplayType,
} from 'src/app/shared/components/ed-table/ed-table.component';

import { Pagination } from 'src/app/shared/all.types';
import { LayerPopupOptions } from 'src/app/shared/all.constants';

import { UsersService } from 'src/app/features/admin/users/users.service';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../organization/organization.service';
import { Organization } from 'src/app/core/models/organization.model';

interface UserTableElement {
    id: string;
    firstname: string;
    lastname: string;
    mail: string;
    createdAt: Date | undefined;
    lastConnexionAt: Date | undefined;
    isAdmin: boolean | undefined;
    isActive: boolean | undefined;
    actions: DropdownMenuItem[];
}
enum UserMenuEvent {
    // Desactive = 'Desactive',
    // Active = 'Active',
    Modify = 'Modify',
    Delete = 'Delete',
}
interface SettingsMenuItem {
    display: string;
    routerLink: any[];
    routerLinkActiveOptions?: any;
}

@UntilDestroy()
@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
    @Select(UsersState.users)
    public users$!: Observable<User[]>;
    @Select(UsersState.hasMoreUsers)
    public hasMoreUsers$!: Observable<boolean>;
    public hasUsers$!: Observable<boolean>;
    @Select(UsersPaginationState.users)
    public paginationInfo$!: Observable<Pagination>;

    loggedUser$: Observable<User | null> = inject(Store).select(AuthState.loggedUser);

    private loggedUser: User | undefined;
    public isLoadingUsers = false;

    private _subscriptions: Subscription[] = [];
    public filterChanged = new Subject<string>();

    public dataSource: MatTableDataSource<UserTableElement> =
        new MatTableDataSource<UserTableElement>();
    public menuItems$: Observable<SettingsMenuItem[]> | undefined;
    public organizationId: string | null = null;
    public myOrga: Organization | undefined | null;

    public displayedColumns: DisplayedColumns[] = [
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Nom',
            objectKey: 'lastname',
            bold: true,
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Prénom',
            objectKey: 'firstname',
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Adresse mail',
            objectKey: 'mail',
        },
        {
            displayType: DisplayType.DATE,
            objectDisplayName: 'Date de création',
            objectKey: 'createdAt',
        },
        {
            displayType: DisplayType.DATE,
            objectDisplayName: 'Dernière connexion',
            objectKey: 'lastConnexionAt',
        },
        // {
        //     displayType: DisplayType.TEXT,
        //     objectDisplayName: 'Administrateur',
        //     objectKey: 'isAdmin',
        // },
        // {
        //     displayType: DisplayType.TEXT,
        //     objectDisplayName: 'Compte actif',
        //     objectKey: 'isActive',
        // },
    ];

    public paginationInfo: Pagination = {
        orderBy: { field: 'createdAt', order: -1 },
        page: 1,
        search: '',
        organizationId: this.organizationId,
    };

    constructor(
        private store: Store,
        private readonly layerService: LayerService,
        private usersService: UsersService,
        private readonly popupService: PopupService,
        private readonly alertService: AlertService,
        private readonly route: ActivatedRoute,
        private organizationService: OrganizationService,
    ) {}

    ngOnInit() {
        this.organizationService.organization$.subscribe((org) => {
            if (org) {
                this.myOrga = org;
                this.organizationId = org.id;

                this.paginationInfo = {
                    page: 1,
                    organizationId: this.organizationId,
                };

                this.store.dispatch(new GetPaginatedUsers(this.paginationInfo, true));
            }
        });
        //const paginationInfo = this.store.selectSnapshot(UsersPaginationState.users);
        //const payload = paginationInfo ? { ...paginationInfo, page: 1 } : undefined;

        this.loggedUser$
            .pipe(untilDestroyed(this))
            .subscribe((data: any) => (this.loggedUser = data));

        this.hasUsers$ = this.users$!.pipe(
            map((users) => users?.length > 0),
            untilDestroyed(this),
        );

        this._subscriptions.push(
            combineLatest([this.users$])
                .pipe(untilDestroyed(this))
                .subscribe(([users]) => {
                    users = users || [];
                    this.isLoadingUsers = false;

                    this.dataSource = new MatTableDataSource<UserTableElement>(
                        users!.map((user) => {
                            const isConnected = this.loggedUser?.id === user.id;
                            return {
                                id: user.id,
                                firstname: user.firstname,
                                lastname: user.lastname,
                                mail: user.mail,
                                createdAt: user.createdAt,
                                lastConnexionAt: user.lastConnexionAt,
                                isAdmin: user.isAdmin,
                                isActive: user.isActive,
                                actions: this._getActions(user.isActive, isConnected),
                            };
                        }),
                    );
                    // Hack to forbid mat table to re-sort wrongly our table
                    this.dataSource.sortData = (data: UserTableElement[]) => {
                        return data;
                    };
                }),
        );

        this._subscriptions.push(
            this.filterChanged
                .pipe(debounceTime(800), distinctUntilChanged(), untilDestroyed(this))
                .subscribe({
                    next: (result) => {
                        this.paginationInfo.search = result;
                        this.paginationInfo.page = 1;
                        this.paginationInfo.organizationId = this.organizationId;
                        this.store.dispatch(new GetPaginatedUsers(this.paginationInfo, true));
                    },
                }),
        );

        this.menuItems$ = of(null).pipe(
            map(() => {
                const items = [
                    {
                        display: 'Utilisateurs',
                        routerLink: ['/admin/users'],
                        routerLinkActiveOptions: { exact: false },
                    },
                ];

                return items;
            }),
            untilDestroyed(this),
        );
    }

    public deleteUser(user: User): void {
        const idOrga = this.organizationId;
        this.popupService
            .openPopup({
                title: 'Supprimer le membre',
                htmlContent: `Confirmez-vous la suppression du membre  <b>${user.firstname} ${user.lastname}</b> de l’organisation ?`,
                confirmButtonText: 'Supprimer le membre',
                type: PopupTypes.Delete,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.store
                        .dispatch(new DeleteUser({ user: user, organizationId: idOrga }))
                        .pipe(untilDestroyed(this))
                        .subscribe({
                            next: () => {
                                this.alertService.valid(
                                    'Suppression',
                                    "L'utilisateur a bien été supprimé",
                                );
                            },
                            error: (err) => {
                                this.alertService.error(
                                    'Suppression',
                                    "une erreur est survenue : Impossible de supprimer l'utilisateur",
                                );
                            },
                        });
                }
            });
    }

    public deActivateUser(user: User): void {
        var header = 'Confirmation';
        var contentText = `Voulez-vous désactiver l'utilisateur ${user.mail}?`;
        var buttonConfirmText = 'Désactiver';
        var buttonUndoText = 'Annuler';
        const organizationId = this.organizationId;
        const userToUpdate: User = {
            id: user.id,
            mail: user.mail,
            firstname: user.firstname,
            lastname: user.lastname,
            password: user.password,
            isAdmin: user.isAdmin,
            isActive: false,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            lastConnexionAt: user.lastConnexionAt,
        };
        user = userToUpdate;
        this.layerService.show(
            EdModalConfirmComponent,
            { user, header, contentText, buttonConfirmText, buttonUndoText, organizationId },
            { size: LayerSize.Medium },
        );
    }

    public activateUser(user: User): void {
        var header = 'Confirmation';
        var contentText = `Voulez-vous activer l'utilisateur ${user.mail}?`;
        var buttonConfirmText = 'Activer';
        var buttonUndoText = 'Annuler';
        const organizationId = this.organizationId;
        const userToUpdate: User = {
            id: user.id,
            mail: user.mail,
            firstname: user.firstname,
            lastname: user.lastname,
            password: user.password,
            isAdmin: user.isAdmin,
            isActive: true,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            lastConnexionAt: user.lastConnexionAt,
        };
        user = userToUpdate;
        this.layerService.show(
            EdModalConfirmComponent,
            { user, header, contentText, buttonConfirmText, buttonUndoText, organizationId },
            { size: LayerSize.Medium },
        );
    }

    public openModalUpdateUser(user: User): void {
        var header = 'Modifier un utilisateur';
        const organizationId = this.organizationId;
        this.layerService.show(
            UserUpdateComponent,
            { user, header, organizationId },
            { size: LayerSize.Medium, withClosePopup: LayerPopupOptions.Editing },
        );
    }

    public openModalCreateUser(): void {
        var header = 'Ajouter un utilisateur à l’organisation ' + this.myOrga?.name;
        const organizationId = this.organizationId;
        const sub = this.users$
            .pipe(first())
            .subscribe((org) =>
                this.layerService.show(
                    UserCreateComponent,
                    { header, organizationId },
                    { size: LayerSize.Medium, withClosePopup: LayerPopupOptions.Creating },
                ),
            );

        this._subscriptions.push(sub);
    }

    private _getActions(isActive: boolean | undefined, isConnected: boolean): DropdownMenuItem[] {
        const actions: DropdownMenuItem[] = [];
        // if (!isConnected) {
        //     if (isActive) {
        //         actions.push({
        //             icon: 'icon-eye',
        //             text: 'Désactiver',
        //             outputEventString: UserMenuEvent.Desactive,
        //         });
        //     } else {
        //         actions.push({
        //             icon: 'icon-eye',
        //             text: 'Activer',
        //             outputEventString: UserMenuEvent.Active,
        //         });
        //     }
        // }

        actions.push({
            icon: 'icon-edit-02',
            text: "Editer l'utilisateur",
            outputEventString: UserMenuEvent.Modify,
        });

        if (!isConnected) {
            actions.push({
                icon: 'icon-trash-01',
                text: "Supprimer l'utilisateur",
                outputEventString: UserMenuEvent.Delete,
            });
        }

        return actions;
    }

    public onDropdownMenuClick(user: User, event: string): void {
        switch (event) {
            // case UserMenuEvent.Desactive:
            //     this.deActivateUser(user);
            //     break;
            // case UserMenuEvent.Active:
            //     this.activateUser(user);
            //     break;
            case UserMenuEvent.Modify:
                this.openModalUpdateUser(user);
                break;
            default:
                this.deleteUser(user);
                break;
        }
    }

    public async goToDetail(idUser: number): Promise<void> {
        const user = await this.usersService.getUserById(idUser);
        this.openModalUpdateUser(user);
    }

    public onSortChange(sort: MatSort): void {
        const orderBy = sort?.active
            ? {
                  field: sort.active,
                  order: sort.direction === 'asc' ? 1 : sort.direction === 'desc' ? -1 : 0,
              }
            : undefined;
        this.paginationInfo = {
            orderBy,
            page: 1,
            search: this.paginationInfo.search,
            organizationId: this.organizationId,
        };
        this.store.dispatch(new GetPaginatedUsers(this.paginationInfo, true));
    }

    public displayMoreUsers(): void {
        this.isLoadingUsers = true;
        this.paginationInfo.page++;
        this.store.dispatch(new GetPaginatedUsers(this.paginationInfo));
    }

    public applyFilter(event: string): void {
        this.paginationInfo.search = event;
        this.filterChanged.next(event);
    }
}
