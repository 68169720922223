import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Organization } from '../../../core/models/organization.model';
import { OrganizationService } from './organization.service';

/** This file is divided in 3 parts:
 * - The definition of the Store : defining data structure
 * - Constants that allow NgXs to trigger Actions
 * - Selectors & Actions : Functions allowed to interact with the store
 *
 */

// Store definition
export interface OrganizationStateModel {
    organizations: Organization[];
    organization: Organization | null;
    organizationPart: Partial<Organization> | null;

    isLoading: boolean;
}

//Constants

export class GetAllOrganizations {
    static readonly type = 'ADMIN/ORGANIZATION/ALL';
}

export class DeleteOrganization {
    static readonly type = 'ADMIN/ORGANIZATION/DELETE';
    constructor(public payload: { orgaId: string }) {}
}

export class UpdateOrganization {
    static readonly type = 'ADMIN/ORGANIZATION/UPDATE';
    constructor(public payload: { organizationPart: Partial<Organization> }) {}
}

export class createOrganization {
    static readonly type = 'ADMIN/ORGANIZATION/CREATE';
    constructor(public payload: { organizationPart: Partial<Organization> }) {}
}

export class GetOrganizationById {
    static readonly type = 'ADMIN/ORGANIZATION/ID';
    constructor(public payload: { orgaId: string }) {}
}

//Actions

@State<OrganizationStateModel>({
    name: 'AdminOrganizations',
    defaults: {
        organizations: [],
        organization: null,
        organizationPart: null,
        isLoading: false,
    },
})
@Injectable()
export class OrganizationState {
    @Selector()
    public static organizations(state: OrganizationStateModel): Organization[] | null {
        return state.organizations;
    }

    @Selector()
    public static getOrganization(state: OrganizationStateModel): Organization | null {
        return state.organization;
    }

    @Selector()
    static isLoading(state: OrganizationStateModel): boolean {
        return state.isLoading;
    }

    constructor(
        private organizationService: OrganizationService,
        private readonly store: Store,
    ) {}

    @Action(GetAllOrganizations)
    public async getAllOrganizations(
        ctx: StateContext<OrganizationStateModel>,
        action: GetAllOrganizations,
    ): Promise<void> {
        ctx.patchState({ isLoading: true });

        const organizations = await this.organizationService.getAllOrganizations();
        ctx.patchState({ organizations: organizations, isLoading: false });
    }

    @Action(UpdateOrganization)
    public async updateOrganization(
        ctx: StateContext<OrganizationStateModel>,
        action: UpdateOrganization,
    ): Promise<void> {
        try {
            const msg = await this.organizationService.updateOrganization(
                action.payload.organizationPart,
            );
        } catch (err: any) {
            console.log(err);
        }
    }

    @Action(createOrganization)
    public async createOrganization(
        ctx: StateContext<OrganizationStateModel>,
        action: createOrganization,
    ): Promise<void> {
        try {
            const msg = await this.organizationService.createOrganization(
                action.payload.organizationPart,
            );
            ctx.dispatch(GetAllOrganizations);
            return msg;
        } catch (err: any) {
            console.log(err);
        }
    }

    @Action(DeleteOrganization)
    public async deleteOrganization(
        ctx: StateContext<OrganizationStateModel>,
        action: DeleteOrganization,
    ): Promise<void> {
        try {
            const msg = await this.organizationService.deleteOrganization(action.payload.orgaId);
            ctx.dispatch(GetAllOrganizations);
        } catch (err: any) {
            console.log(err);
        }
    }

    @Action(GetOrganizationById)
    public async getInfoOrganization(
        ctx: StateContext<OrganizationStateModel>,
        action: GetOrganizationById,
    ): Promise<void> {
        ctx.patchState({ isLoading: true });

        const organization = await this.organizationService.getOrganizationById(
            action.payload.orgaId,
        );
        return ctx.patchState({ organization: organization });
    }
}
