<div class="page-content">
  <div class="page-body page-body px-1 py-4">
      <form [formGroup]="orgaFormGroup">

      <div class="row mb-3">
        <div class="col-2 combined-label display-label-on-the-left d-flex align-items-center">
            <div>
                <label for="name"> Icône d'organisation</label>
            </div>
        </div>
        <div class="col-5">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col-sm">
                          <label class="custom-file-upload">
                            <input type="file" (change)="onFileSelect($event)">
                              <div class="avatar-upload"
                                   tippyClassName="ed-custom-tippy"
                                   [tippyProps]="{
                                           allowHTML: true,
                                           placement: 'top',
                                           content: '<div><b>Téléverser un avatar</b></div><div>Format rond ou carré (JPG, PNG, TIFF)</div>'
                                         }"
                                   ngxTippy>
                                <i class="icon icon-upload-cloud-02"></i>
                              </div>
                          </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div class="row mb-3">
        <div class="col-2 combined-label display-label-on-the-left d-flex align-items-center">
            <div>
                <label for="name" class="required"> Nom de l'organisation </label>
            </div>
        </div>
        <div class="col-5">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col-sm">
                          <ed-textfield
                          innerId="name"
                          name="name"
                          type="name"
                          placeholder=""
                          [required]="true"
                          [formControlName]="'name'"></ed-textfield>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sticky-bottom-buttons" *ngIf="orgaFormGroup.dirty && !isSubmitting">
      <ed-button
          class="left"
          [icon]="'icon-flip-backward'"
          (click)="resetForm()"
          [colorSchema]="buttonColors.Secondary">Annuler les modifications</ed-button>

      <ed-button
          [type]="buttonTypes.Submit"
          [disabled]="orgaFormGroup.invalid || !orgaFormGroup.dirty"
          [icon]="'icon-save-01'"
          (click)="onSubmit()"
          >Enregistrer</ed-button
      >
  </div>
  </form>
  </div>
</div>
