import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService, ButtonColors, LayerService } from '@ed---interne/ng-uui-components';
import { User } from 'src/app/core/models/user.model';
import { createOrganization } from 'src/app/features/admin/organization/organization.state';
import { emailValidator } from 'src/app/shared/validators/email.validator';
import { passwordValidator } from 'src/app/shared/validators/password.validator';
import { Organization } from 'src/app/core/models/organization.model';

export interface ElementClickData {
    object: any;
    objectKey: string;
    event?: any;
}

@UntilDestroy()
@Component({
    selector: 'organization-create',
    templateUrl: './organization-create.component.html',
    styleUrls: ['./organization-create.component.scss'],
})
export class OrganizationCreateComponent {
    public buttonColors = ButtonColors;

    public user!: User;
    public password: string | null = null;
    public passwordConfirm: string | null = null;
    public header: any;
    public orgaFormGroup: FormGroup = this.formBuilder.group({
        name: [''],
    });

    @Output()
    public onElementClick = new EventEmitter<ElementClickData>();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly store: Store,
        private readonly alertService: AlertService,
    ) {
        this.orgaFormGroup = this.formBuilder.group({
            name: [''],
        });
    }

    public valid(): void {
        const { name } = this.orgaFormGroup.value;
        const orgaToUpdate: Partial<Organization> = {
            name: name,
        };
        //create
        this.store.dispatch(new createOrganization({ organizationPart: orgaToUpdate })).subscribe({
            next: () => {
                this.alertService.valid('Organsiations', 'Organisation créée !');
            },
            error: (err) => {
                this.alertService.error(
                    'Création',
                    "une erreur est survenue : Impossible de créer l'organisation",
                );
            },
        });
        this.close();
    }

    isMail(input: string): boolean {
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return expression.test(input);
    }

    public set params(params: { header: any; user: User }) {
        this.user = params.user;
        this.header = params.header;

        this.orgaFormGroup.patchValue({
            email: this.user.mail,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            isAdmin: this.user.isAdmin,
            password: '',
            passwordConfirm: '',
        });
    }

    public close(): void {
        this.layerService.close();
    }

    public canCreateEdit(): boolean {
        return this.orgaFormGroup.invalid || !this.orgaFormGroup.dirty;
    }

    public elementClicked(object: object, objectKey: string, event?: any): void {
        this.onElementClick.emit({ object, objectKey, event });
    }

    public onFileSelect(event: any): void {
        console.log(event);
    }
    public showPopupOnLayerClose(): boolean {
        return this.orgaFormGroup.dirty;
    }
}
