import { Component, inject, OnInit } from '@angular/core';
import { filter, Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatTableDataSource } from '@angular/material/table';
import {
    AlertService,
    DropdownMenuItem,
    PopupService,
    PopupTypes,
} from '@ed---interne/ng-uui-components';

import { DocumentsState, GetAllDocuments } from '../../../shared/states/documents.state';
import {
    DisplayedColumns,
    DisplayType,
} from 'src/app/shared/components/ed-table/ed-table.component';
import { Document } from 'src/app/core/models/document.model';
import { DocumentService } from 'src/app/core/services/document.service';

enum UserMenuEvent {
    Download = 'Download',
    Delete = 'Delete',
}
interface PropertyTableElement {
    id: string;
    folder: string;
    name: string;
    actions: DropdownMenuItem[];
}

@UntilDestroy()
@Component({
    selector: 'app-files',
    templateUrl: './files.component.html',
    styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit {
    documents$: Observable<Document[]> = inject(Store)
        .select(DocumentsState.documents)
        .pipe(filter((documents): documents is Document[] => documents !== null));

    constructor(
        private store: Store,
        private documentService: DocumentService,
        private readonly popupService: PopupService,
        private readonly alertService: AlertService,
    ) {}

    isUploading = false;
    folderName = 'myFolder';

    public dataSource: MatTableDataSource<PropertyTableElement> =
        new MatTableDataSource<PropertyTableElement>();
    private _subscriptions: Subscription[] = [];

    public displayedColumns: DisplayedColumns[] = [
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Dossier',
            objectKey: 'folder',
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Nom du fichier',
            objectKey: 'name',
        },
    ];

    public onDropdownMenuClick(document: Document, event: string): void {
        switch (event) {
            case UserMenuEvent.Download:
                this.download(document);
                break;
            default:
                this.delete(document);
                break;
        }
    }

    ngOnInit() {
        this.getAllDocuments();

        this._subscriptions.push(
            this.documents$.pipe(untilDestroyed(this)).subscribe((documents) => {
                documents = documents || [];

                this.dataSource = new MatTableDataSource<PropertyTableElement>(
                    documents.map((document) => {
                        return {
                            id: document.id,
                            folder: document.folder,
                            name: document.name,
                            actions: this._getActions(),
                        };
                    }),
                );
            }),
        );
    }

    async getAllDocuments(): Promise<void> {
        this.store.dispatch(new GetAllDocuments({ folderName: this.folderName }));
    }
    async onFileUpload(file: File) {
        this.isUploading = true;
        try {
            const newDoc: Document = await this.documentService.upload(this.folderName, file);
            this.alertService.valid(
                'Document',
                `Le fichier "${newDoc.name}" a bien été enregistré`,
            );
        } catch (err) {
            this.alertService.error(
                'Document',
                `Une erreur s'est produite : Le fichier "${file.name}" n'a pas pu être enregistré`,
            );
            console.log(err);
        } finally {
            this.isUploading = false;
            this.getAllDocuments();
        }
    }
    async download(doc: Document) {
        return this.documentService
            .get(doc.id)
            .pipe(untilDestroyed(this))
            .subscribe((blob: any) => {
                const a = document.createElement('a');
                const objectUrl = URL.createObjectURL(blob);
                a.href = objectUrl;
                a.download = doc.name;
                a.click();
                URL.revokeObjectURL(objectUrl);
            });
    }

    public delete(doc: Document): void {
        this.popupService
            .openPopup({
                title: 'Supprimer le fichier',
                htmlContent: `Confirmez-vous la suppression de ce fichier <b>${doc.name}?`,
                confirmButtonText: 'Supprimer',
                type: PopupTypes.Delete,
            })
            .then(async (result) => {
                if (!result.isConfirmed) {
                    return;
                }

                try {
                    await this.documentService.delete(doc.id);
                    this.alertService.valid('Document', 'Suppression réussie');
                } catch (error) {
                    this.alertService.error(
                        'Document',
                        'Erreur lors de la suppression du document',
                    );
                }
            });
    }

    private _getActions(): DropdownMenuItem[] {
        const actions: DropdownMenuItem[] = [];

        actions.push(
            {
                icon: 'icon-download-cloud-01',
                text: 'Télécharger',
                outputEventString: UserMenuEvent.Download,
            },
            {
                icon: 'icon-trash-01',
                text: 'Supprimer',
                outputEventString: UserMenuEvent.Delete,
            },
        );

        return actions;
    }
}
