<div class="page-heading modal-content-header">
    <div class="layer-title">{{ header }}</div>
</div>

<div class="modal-content-body" [formGroup]="orgaFormGroup">
    <div class="panel-row with-top-margin d-flex align-items-center">
        <label>Icône d'organisation</label>
        <label class="custom-file-upload px-5">
          <input type="file" (change)="onFileSelect($event)">
            <div class="avatar-upload"
                 tippyClassName="ed-custom-tippy"
                 [tippyProps]="{
                         allowHTML: true,
                         placement: 'top',
                         content: '<div><b>Téléverser un avatar</b></div><div>Format rond ou carré (JPG, PNG, TIFF)</div>'
                       }"
                 ngxTippy>
              <i class="icon icon-upload-cloud-02"></i>
            </div>
        </label>
      </div>
    <div class="panel-row">
        <label for="name" class="required">Nom de l'organisation</label>
        <ed-textfield
            innerId="name"
            name="name"
            type="emnameail"
            placeholder=""
            [required]="true"
            [formControlName]="'name'"></ed-textfield>
    </div>
</div>

<div class="layer-actions">
    <ed-button [colorSchema]="buttonColors.Secondary" (click)="close()">Retour</ed-button>
    <ed-button (click)="valid()" [disabled]="canCreateEdit()">Ajouter l'organisation</ed-button>
</div>
