<div class="page-heading modal-content-header">
    <div class="layer-title">{{ header }}</div>
</div>

<div class="modal-content-body" [formGroup]="userFormGroup">
    <div class="panel-row">
        <label for="email" class="required">Email de l'utilisateur</label>
        <ed-textfield
            innerId="email"
            name="email"
            type="email"
            placeholder="jeanne.martin@aecenergie.fr"
            [required]="true"
            [formControlName]="'email'"></ed-textfield>
    </div>
    <div class="form-row">
        <div class="half-width">
            <label for="firstname" class="required">Prénom</label>
            <ed-textfield
                innerId="firstname"
                name="firstname"
                type="text"
                placeholder="Jeanne"
                [required]="true"
                [formControlName]="'firstname'"></ed-textfield>
        </div>
        <div class="half-width">
            <label for="Name" class="required">Nom</label>
            <ed-textfield
                innerId="lastname"
                name="lastname"
                type="text"
                placeholder="Martin"
                [required]="true"
                [formControlName]="'lastname'"></ed-textfield>
        </div>
    </div>
    <!-- <div class="form-row">
        <div class="half-width checkboxAdmin">
            <input type="checkbox" formControlName="isAdmin" class="form-check-input" />
            Administrateur
        </div>
    </div> -->
</div>

<div class="layer-actions">
    <ed-button [colorSchema]="buttonColors.Secondary" (click)="close()">Retour</ed-button>
    <ed-button (click)="valid()" [disabled]="canCreateEdit()">Modifier l'utilisateur</ed-button>
</div>
