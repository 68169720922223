import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService, ButtonColors, LayerService } from '@ed---interne/ng-uui-components';
import { Organization } from 'src/app/core/models/organization.model';
import { GetOrganizationById, OrganizationState, UpdateOrganization } from '../organization.state';
import { map, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '../organization.service';

interface SettingsMenuItem {
    display: string;
    routerLink: any[];
    routerLinkActiveOptions?: any;
}

@UntilDestroy()
@Component({
    selector: 'organization-detail',
    templateUrl: './organization-detail.component.html',
    styleUrls: ['./organization-detail.component.scss'],
})
export class OrganizationDetailComponent implements OnInit {
    public menuItems$: Observable<SettingsMenuItem[]> | undefined;
    public organizationId: string | null = null;
    public myOrga: Organization | undefined | null;

    constructor(
        private readonly alertService: AlertService,
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private organizationService: OrganizationService,
    ) {}

    ngOnInit() {
        this.route.paramMap.pipe(untilDestroyed(this)).subscribe((paramMap) => {
            this.organizationId = paramMap.get('id');

            const idOrga = this.organizationId;
            const payload = { orgaId: idOrga! };
            this.store.dispatch(new GetOrganizationById(payload));

            this.store.select(OrganizationState.getOrganization).subscribe((organization) => {
                this.myOrga = organization; // Assigner la valeur à `myOrga`
                this.organizationService.setOrganization(this.myOrga); // Envoyer au service
            });
        });

        this.menuItems$ = of(null).pipe(
            map(() => {
                const items = [
                    {
                        display: 'Général',
                        routerLink: ['./general'],
                        routerLinkActiveOptions: { exact: false },
                    },
                    {
                        display: 'Utilisateurs',
                        routerLink: ['./users'],
                        routerLinkActiveOptions: { exact: false },
                    },
                    {
                        display: 'Gérer les études disponibles',
                        routerLink: ['./manageStudies'],
                        routerLinkActiveOptions: { exact: false },
                    },
                ];

                return items;
            }),
            untilDestroyed(this),
        );
    }
}
