<div class="page-content">
    <div class="page-body search-bar">
        <div class="search-and-button">
            <ed-textfield
                [ngModel]="paginationInfo.search"
                (ngModelChange)="applyFilter($event)"
                placeholder="Chercher un prénom, nom, mail"
                [icon]="'icon-search-lg'"></ed-textfield>
            <ed-button *ngIf="!isLoadingUsers" class="btnSecondary" [icon]="'icon-plus'" (click)="openModalCreateUser()">
                Ajouter un utilisateur
            </ed-button>
        </div>
    </div>
</div>
<div class="stretch-container">
    <ed-table
        (onDropdownMenuClick)="onDropdownMenuClick($event.object, $event.event)"
        (onRowClick)="goToDetail($event)"
        [dataSource]="dataSource"
        [displayedColumns]="displayedColumns"
        [areActionsInElements]="true"
        EdInfiniteScroll
        [listenToScroll]="hasMoreUsers$ | async"
        [sortInfo]="paginationInfo.orderBy"
        (nearEnd)="displayMoreUsers()"
        (onSortChanged)="onSortChange($event)">
    </ed-table>
    <!-- when more users to display -->
    <ed-skeleton-list *ngIf="isLoadingUsers"></ed-skeleton-list>
    @if ((users$ | async) === null) {
        <ed-skeleton-list></ed-skeleton-list>
    }
</div>
