import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

import { AlertService, ButtonColors, LayerService } from '@ed---interne/ng-uui-components';
import { User } from '../../../../core/models/user.model';
import { UpdateUser, GetUsers } from '../../../../features/admin/users/users.state';
import { Organization } from 'src/app/core/models/organization.model';

@UntilDestroy()
@Component({
    selector: 'EdModalConfirm',
    templateUrl: './ed-modal-confirm.component.html',
    styleUrls: ['./ed-modal-confirm.component.scss'],
})
export class EdModalConfirmComponent {
    public user!: User;
    public header: any;

    public contentText: any;
    public buttonUndoText: any;
    public buttonConfirmText: any;
    public buttonConfirmVariant: any;

    public buttonColors = ButtonColors;
    public organizationId: any;

    constructor(
        private readonly alertService: AlertService,
        private readonly layerService: LayerService,
        private readonly store: Store,
    ) {}

    confirm() {
        const orgaToCreate: Partial<Organization> = { id: this.organizationId };
        this.user.organization = orgaToCreate;
        this.store.dispatch(new UpdateUser({ user: this.user })).subscribe({
            next: () => {
                this.alertService.valid(
                    'Utilisateurs',
                    this.buttonConfirmText === 'Désactiver'
                        ? 'Utilisateur désactivé !'
                        : 'Utilisateur activé !',
                );
            },
            error: (err) => {
                this.alertService.error(
                    'Enregistrement',
                    "une erreur est survenue : Impossible de mettre à jour l'utilisateur",
                );
            },
        });
        this.close();
    }

    undo() {
        this.close();
    }

    public set params(params: {
        user: User;
        header: string;
        buttonConfirmText: string;
        contentText: string;
        buttonUndoText: string;
        organizationId: string;
    }) {
        this.user = params.user;
        this.header = params.header;
        this.contentText = params.contentText;
        this.buttonConfirmText = params.buttonConfirmText;
        this.buttonUndoText = params.buttonUndoText;
        this.organizationId = params.organizationId;
    }

    public close(): void {
        this.layerService.close();
    }
}
