<header>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
            <button
                *ngIf="isSmallScreen"
                class="navbar-toggler d-lg-inline-block"
                type="button"
                (click)="toggleSidenav()">
                <i class="icon-menu-01"></i>
            </button>

            <a
                class="navbar-brand"
                href="https://energies-demain.com/"
                target="_blank"
                title="energies-demain.com"
                [ngClass]="{ 'me-0': isSmallScreen }">
                <img src="/assets/img/logo_header.png" alt="logo" height="40" />
                <h4 class="project-name" *ngIf="!isSmallScreen">{{ projectName }}</h4>
            </a>

            <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed">
                <i class="bi bi-three-dots"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed">
                <form [formGroup]="headerFormGroup" class="me-auto">
                @if (currentPage=='admin') {
                    <ul class="navbar-nav">
                        <li class="navbar-header">
                            <span class="breadcrumbs">
                                <a routerLink="/admin/organization">Administration</a>
                            </span>
                        </li>
                        <li  class="navbar-header"  *ngIf="!isSmallScreen && organization?.id">
                            <i class="bi bi-chevron-right" ></i>
                        </li>
                        <li class="nav-item select-example" *ngIf="organization?.id">
                            <ed-select
                                formControlName="mySelect"
                                [disableRipple]="true"
                                [disableOptionCentering]="true"
                                selectId="select-example"
                                placeholder="Select a value"
                                [options]="selectListOrga">
                            </ed-select>
                        </li>

                        
                    </ul>
                } @else {
                    <ul class="navbar-nav">
                        <li class="navbar-header">
                            <span>Mon espace</span>
                        </li>
                        <li  class="navbar-header"  *ngIf="!isSmallScreen">
                            <i class="bi bi-chevron-right" ></i>
                        </li>
                        
                        <li class="nav-item select-example">
                            <ed-select
                                formControlName="mySelectControl"
                                [disableRipple]="true"
                                [disableOptionCentering]="true"
                                selectId="select-example"
                                placeholder="Select a value"
                                [options]="selectOptions">
                            </ed-select>
                        </li>
                        <li  class="navbar-header"  *ngIf="!isSmallScreen">
                            <i class="bi bi-chevron-right" ></i>
                        </li>
                        
                        <li class="nav-item select-year">
                            <ed-select
                                formControlName="yearSelect"
                                [disableRipple]="true"
                                [disableOptionCentering]="true"
                                selectId="select-example"
                                placeholder="Select a value"
                                [options]="yearOptions">
                            </ed-select>
                        </li>
                        <li  class="navbar-header"  *ngIf="!isSmallScreen">
                            <i class="bi bi-chevron-right" ></i>
                        </li>
                        
                        <li class="nav-item searchable-input-example">
                            <ed-textfield
                                class="searchableInput"
                                formControlName="myInput"
                                placeholder="Rechercher"
                                [noBorder]="false"
                                [displayError]="false"
                                [icon]="'icon-search-lg'"
                                [matAutocomplete]="auto"
                                [displayWith]="displayFn">
                            </ed-textfield>

                            <mat-autocomplete #auto="matAutocomplete">
                                <h5 class="search-result-header px-2" *ngIf="searchResults.length">
                                    <i class="bi bi-geo-alt-fill"></i>
                                    Regions
                                </h5>
                                <mat-option
                                    class="px-4"
                                    *ngFor="let option of searchResults"
                                    [value]="option">
                                    <span class="search-result-main-text">
                                        {{ option.name }} - ({{ option.code }})
                                    </span>
                                </mat-option>
                            </mat-autocomplete>
                        </li>
                        
                    </ul>
                }
                </form>

                <div class="profile">
                    <div mat-icon-button [matMenuTriggerFor]="menu">
                        @if (avatarUrl$ | async; as avatarUrl) {
                            <img [src]="avatarUrl" width="40" height="40" alt="avatar" />
                        } @else {
                            <div class="circle">
                                <span class="initials">
                                    {{ initials$ | async }}
                                </span>
                            </div>
                        }
                    </div>

                    <mat-menu #menu="matMenu" class="header-dropdown">
                        <button mat-menu-item class="user-info">
                            <div>
                                @if (avatarUrl$ | async; as avatarUrl) {
                                    <img [src]="avatarUrl" width="40" height="40" alt="avatar" />
                                } @else {
                                    <div class="circle">
                                        <span class="initials">
                                            {{ initials$ | async }}
                                        </span>
                                    </div>
                                }
                            </div>
                            <div>
                                <div class="primary-info">
                                    {{ loggedUser.firstname }} {{ loggedUser.lastname }}
                                </div>
                                <div class="secondary-info">
                                    {{ loggedUser.mail }}
                                </div>
                            </div>
                        </button>

                        <button mat-menu-item routerLink="/main/account" class="btn-cta">
                            <i class="icon icon-user-01"></i>
                            <span>Mon profil</span>
                        </button>

                        @if (loggedUser.isAdmin) {
                            <button mat-menu-item routerLink="/admin/organization" class="btn-cta">
                                <i class="icon icon-tool-01"></i>
                                <span>Administration</span>
                            </button>
                        }
                        <button mat-menu-item (click)="logOut()" class="btn-cta">
                            <i class="icon icon-log-out-01"></i>
                            <span>Déconnexion</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </nav>
</header>
