<div class="page-heading">
    <div class="layer-title">{{ header }}</div>
</div>

<div class="modal-content-body">{{ contentText }}</div>

<div class="layer-actions">
    <ed-button [colorSchema]="buttonColors.Secondary" (click)="close()">{{
        buttonUndoText
    }}</ed-button>
    <ed-button (click)="confirm()">{{ buttonConfirmText }}</ed-button>
</div>
