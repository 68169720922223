import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    AlertService,
    ButtonColors,
    ButtonTypes,
    LayerService,
} from '@ed---interne/ng-uui-components';
import { User } from 'src/app/core/models/user.model';
import { UpdateUser, GetUsers } from 'src/app/features/admin/users/users.state';
import { emailValidator } from 'src/app/shared/validators/email.validator';
import { passwordValidator } from 'src/app/shared/validators/password.validator';
import { Organization } from 'src/app/core/models/organization.model';
import { GetOrganizationById, UpdateOrganization } from '../organization.state';
import { map, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '../organization.service';

interface SettingsMenuItem {
    display: string;
    routerLink: any[];
    routerLinkActiveOptions?: any;
}

@UntilDestroy()
@Component({
    selector: 'organization-general',
    templateUrl: './organization-general.component.html',
    styleUrls: ['./organization-general.component.scss'],
})
export class OrganizationGeneralComponent implements OnInit {
    public menuItems$: Observable<SettingsMenuItem[]> | undefined;
    public organizationId: string | null = null;
    public orgaFormGroup: FormGroup;
    public myOrga: Organization | undefined | null;
    public buttonColors = ButtonColors;
    public buttonTypes = ButtonTypes;
    public isSubmitting: boolean = false;

    constructor(
        private readonly alertService: AlertService,
        private readonly formBuilder: FormBuilder,
        private readonly store: Store,
        private organizationService: OrganizationService,
    ) {
        this.orgaFormGroup = this.formBuilder.group({
            name: [''],
        });
    }

    ngOnInit() {
        this.getInfoOrganization();
        // Observe changes form
        this.orgaFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.isSubmitting && this.orgaFormGroup.dirty) {
                this.isSubmitting = false;
            }
        });
    }

    public getInfoOrganization() {
        this.organizationService.organization$.subscribe((org) => {
            this.myOrga = org;
            this.orgaFormGroup.patchValue({
                name: this.myOrga?.name || '',
            });
        });
    }

    public onFileSelect(event: any): void {
        console.log(event);
    }

    public onSubmit(): void {
        if (this.isSubmitting) {
            return;
        }
        this.isSubmitting = true;

        const { name } = this.orgaFormGroup.value;
        const orgaToUpdate: Partial<Organization> = {
            id: this.myOrga!.id,
            name: name,
        };
        this.store
            .dispatch(new UpdateOrganization({ organizationPart: orgaToUpdate }))
            .pipe(untilDestroyed(this))
            .subscribe({
                next: () => {
                    this.myOrga!.name = name;
                    this.organizationService.setOrganization(this.myOrga);
                    this.organizationService.triggerUpdateListOrga();
                    this.alertService.valid('Organisations', "L'organisation a bien été modifiée");
                    this.orgaFormGroup.markAsPristine();
                    this.isSubmitting = false;
                },
                error: (err) => {
                    this.alertService.error(
                        'Organisations',
                        "une erreur est survenue : Impossible de mettre à jour l'organisation",
                    );
                    this.isSubmitting = false;
                },
            });
    }

    public resetForm(): void {
        this.orgaFormGroup.reset();
        this.isSubmitting = false;
        this.getInfoOrganization();
    }
}
